<template>
  <router-view/>
</template>

<script setup>
  import { useStore} from 'vuex'

  const store = useStore()
  if (store.state.locations === null) {
    store.dispatch('get_locations')
  }

  document.addEventListener('contextmenu', event => event.preventDefault())

</script>

<style lang="scss">

</style>
