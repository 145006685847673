import { createRouter, createWebHistory } from 'vue-router'
import home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      auth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/custom-air',
    component: () => import('../layouts/MainLayout.vue'),
    meta: {
      auth: true
    },
    children: [
      {
        path: '',
        redirect: '/custom-air/results'
      },
      {
        path: 'city',
        name: 'City',
        component: () => import('../views/City.vue'),
      },
      {
        path: 'refrigerant',
        name: 'Refrigerant',
        component: () => import('../views/Refrigerant.vue'),
      },
      {
        path: 'features',
        name: 'Features',
        component: () => import('../views/Features.vue'),
      },
      {
        path: 'clean',
        name: 'Clean',
        component: () => import('../views/Clean.vue'),
      },
      {
        path: 'results',
        name: 'Results',
        component: () => import('../views/Results.vue'),
      },
      {
        path: 'results-pdf',
        name: 'ResultsPdf',
        component: () => import('../views/ResultsPdf.vue'),
        meta: {
          auth: false
        }
      },
      {
        path: 'result-green-air',
        name: 'GreenAir',
        component: () => import('../views/GreenAir.vue'),
      },
      {
        path: 'result-clean-air',
        name: 'CleanAir',
        component: () => import('../views/CleanAir.vue'),
      },
      {
        path: 'result-tco',
        name: 'Tco',
        component: () => import('../views/Tco.vue'),
      },
    ]
  },

]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  const isLogged = window.sessionStorage.getItem('is_user')
  if (to.name === 'Login') {
    isLogged ? next('/home') : next()
  } else {
    to.meta?.auth && !isLogged
        ? next('/login')
        : next()
  }
});
export default router
