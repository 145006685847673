<template>
  <main>
    <div class="wrapper-options">
      <router-link class="link-option flex columns align-center" to="/custom-air">
        <span class="title-option">custom[air]</span>
        <img src="../assets/maqueta.png" alt="Merak">
      </router-link>
    </div>
    <div @click="handleBrochures" class="wrapper-options flex columns align-center">
      <span class="title-option">Brochures</span>
      <img v-if="brochuresPreview" src="../assets/broshur.png" alt="Merak">
      <div v-else class="elements-preview-pdf flex columns flex-center align-center">
        <div class="flex">
          <div class="flex flex-center columns" @click="handleOpenPdf('https://drive.google.com/file/d/1ssGaKRMQcnRgM_N1IlsKjQePjVLkTKLm/preview?srcid=my_pdf_id&pid=explorer&efh=false&a=v&chrome=false&embedded=true')">
            <img src="../assets/b1.png" alt="Merak">
            <h2 class="text-center">clean[air] technologies</h2>
          </div>
          <div class="flex flex-center columns" @click="handleOpenPdf('https://drive.google.com/file/d/1smFxUWaQikAv0IzrI-8wVkjEDDMHTLo4/preview')">
            <img src="../assets/b2.png" alt="Merak">
            <h2 class="text-center">Merak Long Life Filter</h2>
          </div>

          <div class="flex flex-center columns" @click="handleOpenPdf('https://drive.google.com/file/d/1svOg97Sl3CcfzPf3GFdIZeMEgYllMK_l/preview')">
            <img src="../assets/b3.png" alt="Merak">
            <h2 class="text-center no-text-wrap">Merak Dielectric <br /> Barrier Discharge</h2>
          </div>
        </div>

        <div class="flex m-l-90">
          <div class="flex flex-center columns m-r-75" @click="handleOpenPdf('https://drive.google.com/file/d/1GMfeFh6rQAn6QPyCIM68ls3XabN8Kxcf/preview')">
            <img src="../assets/b4.png" alt="Merak">
            <h2 class="text-center">Merak Intense <br /> Field Dielectric</h2>
          </div>
          <div class="flex flex-center columns" @click="handleOpenPdf('https://drive.google.com/file/d/1GMd8H3eqUymwTDtZe7hTJ9AtF3IwlBOm/preview')">
            <img src="../assets/b5.png" alt="Merak">
            <h2 class="text-center">Merak HVAC <br /> Electronic Control</h2>
          </div>
        </div>
      </div>
    </div>
    <div @click="handleVideos" class="wrapper-options flex flex-start columns align-center">
      <span class="title-option-bottom video-title">Videos</span>
      <img v-if="videoPreview" src="../assets/video.png" alt="Merak">
      <div v-else class="elements-preview">
        <div @click="handleOpenVideo('cleanair')" class="elements-preview-wrapper">
          <img src="../assets/video.png" alt="Merak">
          <h2>clean[air] technologies</h2>
        </div>
        <div @click="handleOpenVideo('greenair')" class="elements-preview-wrapper">
          <img src="../assets/greenair.png" alt="Merak">
          <h2>green[air] technologies</h2>
        </div>
        <div @click="handleOpenVideo('merak')" class="elements-preview-wrapper m-l-32 m-r-16">
          <img src="../assets/merak.png" alt="Merak">
          <h2>Merak</h2>
        </div>
        <div @click="handleOpenVideo('ideenzug')" class="elements-preview-wrapper">
          <img src="../assets/ideenzug.png" alt="Merak">
          <h2>DB Ideenzug – Merak Purification island</h2>
        </div>
      </div>
    </div>
    <div @click="handleOpenCleanAirApp()" class="wrapper-options flex flex-start columns align-center">
      <span class="title-option-bottom">clean[air] App</span>
      <img src="../assets/cleanair.png" alt="Merak">
    </div>

    <div class="center-item flex columns align-center" >
      <img src="../assets/logo.png" alt="Merak">
      <p>Scan to discover more</p>
      <img class="qr-code" src="../assets/qr.png" alt="Merak">
    </div>

<!--    <div class="vertical-line-h50t"></div>-->
<!--    <div class="vertical-line-h50b"></div>-->
<!--    <div class="horizontal-line-wl"></div>-->
<!--    <div class="horizontal-line-wr"></div>-->

    <div v-if="isPdf"
         @click="handleClose"
         class="pdf-wrapper">
      <div class="loading-pdf">
        <div class="loading-1">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </div>
      <iframe class="iframe-pdf" :src="pdfLink" allow="autoplay" width="100%" height="100%"></iframe>
      <div @click="handleClose" class="close-pdf"><i v-html="svg.closeX" /></div>
    </div>
    <div v-if="isCleanAirApp"
         @click="handleClose"
         class="app-wrapper">
      <iframe class="iframe-app" src="https://merak-cleanair.com/new/"
              allow="autoplay"
              referrerpolicy="same-origin"
              sandbox="allow-forms allow-scripts allow-top-navigation-by-user-activation allow-same-origin"></iframe>
      <div @click="handleClose" class="close-app"><i v-html="svg.closeX" /></div>
    </div>
    <div v-if="isVideo"
         @click="handleClose"
         class="video-wrapper">
      <iframe v-if="videoLink === 'cleanair'" src="https://www.youtube.com/embed/N1MsV24kSZ8?si=eugKuZpIm6vVYUOP" title="YouTube video player" width="1920" height="1080" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe v-if="videoLink === 'greenair'" src="https://www.youtube.com/embed/s7zj8miKQEg?si=_6V1BoyAI3gAG-E6" title="YouTube video player" width="1920" height="1080" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe v-if="videoLink === 'merak'" src="https://www.youtube.com/embed/N1MsV24kSZ8?si=eugKuZpIm6vVYUOP" title="YouTube video player" width="1920" height="1080" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe v-if="videoLink === 'ideenzug'" src="https://www.youtube.com/embed/igAnqzD-NTA?si=i1CncgSrkbhztMhP" title="YouTube video player" width="1920" height="1080" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <div @click="handleClose" class="close-video"><i v-html="svg.closeX" /></div>
    </div>
  </main>
</template>
<script setup>
  import { ref } from 'vue'
  import { svg } from '@/assets/svg'
  import {useStore} from 'vuex'


  const store = useStore()
  const brochuresPreview = ref(true)
  const videoPreview = ref(true)
  const isPdf = ref(false)
  const isVideo = ref(false)
  const isCleanAirApp = ref(false)
  const pdfLink = ref(undefined)
  const videoLink = ref(undefined)


  store.dispatch('get_combination')

  function handleBrochures () {
    brochuresPreview.value = false
    videoPreview.value = true
  }

  function handleVideos () {
    // videoPreview.value = videoPreview.value === false
    videoPreview.value = false
    brochuresPreview.value = true

  }

  function handleOpenPdf (link) {
    isPdf.value = true
    pdfLink.value = link
  }

  function handleOpenVideo (video) {
    isVideo.value = true
    videoLink.value = video
  }

  function handleOpenCleanAirApp () {
    isCleanAirApp.value = true
    brochuresPreview.value = true
    videoPreview.value = true
  }

  function handleClose () {
    isPdf.value = false
    isVideo.value = false
    isCleanAirApp.value = false
  }
</script>

<style lang="scss">
  @import '../styles/vars';
  main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .wrapper-options {
      width: 50%;
      height: 50%;
      margin: 0;
      padding: 0;
      .link-option {
        height: 100%;
        padding-top: 70px;
      }
      .title-option {
        margin-top: 300px;
        margin-bottom: 75px;
        font-size: 14rem;
        cursor: pointer;
        font-family: "MyriadProCond", "MyriadPro", Arial, sans-serif;
      }
      .title-option-bottom {
        margin-top: 700px;
        font-size: 14rem;
        cursor: pointer;
        font-family: "MyriadProCond", "MyriadPro", Arial, sans-serif;

        &:nth-child(1) {
          text-align: right;
          max-width: 9ch;
          margin-bottom: 0;
        }
      }

      .video-title {
        margin-right: 225px;
        padding-bottom: 200px;
      }




      img {
        cursor: pointer;
      }

      .elements-preview-pdf {
        img {
          width: 200px;
        }
      }

      &:nth-child(3) {
        .elements-preview {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          .elements-preview-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            h2 {
              margin-top: 1rem;
              max-width: 16ch;
              text-align: center;
            }

            img {
              width: 200px;
            }

            &:nth-child(1), &:nth-child(3) {
              margin-right: 50px;
            }
          }
        }
      }
      &:nth-child(3) {
        .elements-preview {
          .elements-preview-wrapper {
            h2 {
              margin-top: 1rem;
              max-width: 16ch;
              text-align: center;
              &:nth-last-child(1) {
                max-width: 18ch;
              }
            }
            img {
              width: 350px;
            }
          }
        }
      }
      &:nth-child(4) {
        img {
          height: 520px;
          margin-top: -50px;
        }
      }
    }

    .center-item {
      position: absolute;
      color: #fff;
      width: 1350px;
      height: 700px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //border: solid 10px #FFFEFD;
      z-index: 9990;
      img {
        width: 60%;
      }
      p {
        font-size: 6rem;
      }
      .qr-code {
        width: 220px;
      }
    }

    //.vertical-line-h50t, .vertical-line-h50b {
    //  position: absolute;
    //  width: 10px;
    //  height: calc(50% - 350px);
    //  background: #FFFFFF;
    //  left: 50%;
    //  transform: translateX(-50%);
    //  z-index: 9001;
    //}
    //
    //.vertical-line-h50b {
    //  bottom: 0;
    //}
    //
    //.horizontal-line-wl, .horizontal-line-wr {
    //  position: absolute;
    //  width: calc(50% - 675px);
    //  height: 10px;
    //  background: #FFFFFF;
    //  top: 50%;
    //  transform: translateY(-50%);
    //  z-index: 9000;
    //}
    //
    //.horizontal-line-wr {
    //  right: 0;
    //}

    .pdf-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9998;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);
      .iframe-pdf {
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%) scale(1.7);
        width: 800px;
        height: 1131px;
        border: 0;

      }
      .loading-pdf {
        position: absolute;
        left: 50%;
        top: 35%;
        transform: translate(-50%, -50%) scale(1.7);
        width: 800px;
        height: 1131px;
        background: #d1d1d1;
        .loading-1{
          position: absolute;
          left: calc(50% - 44px);
          top: 50%;
          transform: translate(-50%, -50%) scale(1.7);
        }
        .loading-1 i {
          position: absolute;
          bottom: 0;
          display: block;
          width: 9px;
          height: 5px;
          background: #0050FF;
          animation: loading-1 1.5s  infinite ease-in-out;
        }
        .loading-1 i:nth-child(2){
          left: 11px;
          animation-delay: .2s;
        }
        .loading-1 i:nth-child(3){
          left:22px;
          animation-delay: .4s;
        }
        .loading-1 i:nth-child(4){
          left:33px;
          animation-delay: .6s;
        }
        .loading-1 i:nth-child(5){
          left:44px;
          animation-delay: .8s;
        }
        @keyframes loading-1 {
          0% { height:5px;transform:translateY(0px);background:#0050FF; }
          25% {height:30px;transform:translateY(15px);background:#00E3E3;}
          50% {height:5px;transform:translateY(0px);background:#0050FF;}
          100% {height:5px;transform:translateY(0px);background:#0050FF;}
        }
      }


      .close-pdf {
        position: absolute;
        width: 75px;
        height: 75px;
        background: #4b4b4b;
        left: 50%;
        top: 35%;
        transform: translate(calc(-50% + 620px), calc(-50% - 905px));
        cursor: pointer;
      }
    }

    .video-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9997;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);

      iframe {
        position: absolute;
        top: 45.5%;
        left: 50%;
        transform: translate(-50%, -50%);

      }
      .close-video {
        position: absolute;
        z-index: 9999;
        width: 75px;
        height: 75px;
        background: #4b4b4b;
        left: 50%;
        top: 50%;
        transform: translate(calc(-50% + 923px), calc(-50% - 677px));
        cursor: pointer;
      }
    }
    .app-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 9998;
      background: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(10px);

      .iframe-app {
        position: absolute;
        width: 1920px;
        height: 1080px;
        top: 45.5%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9998;
        background: #FFFFFF;
      }

      .close-app {
        position: absolute;
        z-index: 9999;
        width: 75px;
        height: 75px;
        background: #4b4b4b;
        left: 50%;
        top: 50%;
        transform: translate(calc(-50% + 923px), calc(-50% - 677px));
        cursor: pointer;
      }
    }
  }

</style>
