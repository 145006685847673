import { createStore } from 'vuex'
import axios from 'axios'
import api from './api.js'

let $axios = axios.create(/*...*/)

$axios.defaults.baseURL = process.env.VUE_APP_API_HOST;

function reset_systems () {
  return {
    system1: {
      title: 'HVAC 1',
      city: {
        name: 'Berlin',
        code: 'D'
      },
      refrigerant: {
        name: 'R513A',
        code: 1
      },
      control: {
        name: 'HGBP',
        code: 2
      },
      heatPump: {
        name: 'HP-off',
        code: 2
      },
      cleanAir: {
        name: 'Conventional',
        code: 1
      },
    },
    system2: {
      title: 'HVAC 2',
      city: {
        name: 'Berlin',
        code: 'D'
      },
      refrigerant: {
        name: 'R513A',
        code: 1
      },
      control: {
        name: 'Tandem',
        code: 1
      },
      heatPump: {
        name: 'HP-on',
        code: 1
      },
      cleanAir: {
        name: 'Advanced',
        code: 2
      },
    }
  }
}
export default createStore({

  state: {
    message: undefined,
    errMessage: undefined,
    loading: false,
    reload: true,
    activeSystem: false,
    systems: {
      system1: {
        title: 'HVAC 1',
        city: {
          name: 'Berlin',
          code: 'D'
        },
        refrigerant: {
          name: 'R513A',
          code: 1
        },
        control: {
          name: 'HGBP',
          code: 2
        },
        heatPump: {
          name: 'HP-off',
          code: 2
        },
        cleanAir: {
          name: 'Conventional',
          code: 1
        },
      },
      system2: {
        title: 'HVAC 2',
        city: {
          name: 'Berlin',
          code: 'D'
        },
        refrigerant: {
          name: 'R513A',
          code: 1
        },
        control: {
          name: 'Tandem',
          code: 1
        },
        heatPump: {
          name: 'HP-on',
          code: 1
        },
        cleanAir: {
          name: 'Advanced',
          code: 2
        },
      }
    },
    locations: null,
    refrigerant: [
      {
        title: 'R513A',
        value: '631',
        eco: 1,
        flammability: 'A1',
        flam: 0,
        code: 1
      },
      {
        title: 'R454C',
        value: '148',
        eco: 2,
        flammability: 'A2L',
        flam: 1,
        code: 2
      },
      {
        title: 'R1234yf',
        value: '4',
        eco: 3,
        flammability: 'A2L',
        flam: 1,
        code: 4
      },
      {
        title: 'R290',
        subtitle: ' / Propane',
        value: '3',
        eco: 4,
        flammability: 'A3',
        flam: 3,
        code: 3
      },
      {
        title: 'R744',
        subtitle: ' / CO',
        subtitleNr: '2',
        value: '1',
        eco: 4,
        flammability: 'A1',
        flam: 0,
        code: 5
      },
    ],
    features: [
      {
        title: 'HGBP',
        subtitle: '(hot gas by-pass)',
        state: 'OFF',
        codeControl: 2,
        codeHP: 2,
        hp: 'HP-off',
        control: 'HGBP',
      },
      {
        title: 'HGBP',
        subtitle: '(hot gas by-pass)',
        state: 'ON',
        codeControl: 2,
        codeHP: 1,
        hp: 'HP-on',
        control: 'HGBP',
      },
      {
        title: 'Tandem',
        state: 'OFF',
        codeControl: 1,
        codeHP: 2,
        hp: 'HP-off',
        control: 'Tandem',
      },
      {
        title: 'Tandem',
        state: 'ON',
        codeControl: 1,
        codeHP: 1,
        hp: 'HP-on',
        control: 'Tandem',
      },
    ],
    featuresInv: [
      {
        title: 'Inverter',
        state: 'OFF',
        codeControl: 3,
        codeHP: 2,
        hp: 'HP-off',
        control: 'Inverter',
      },
      {
        title: 'Inverter',
        state: 'ON',
        codeControl: 3,
        codeHP: 1,
        hp: 'HP-on',
        control: 'Inverter',
      }
    ],
    specs: [
      {
        title: 'Conventional',
        code: 1,
        spec1: {
          name: 'Filtration Coarse',
          stamp: true
        },
        spec2: {
          name: 'Fresh air maximization',
          stamp: false
        },
        spec3: {
          name: 'Purification',
          stamp: false
        }
      },
      {
        title: 'Advanced',
        code: 2,
        spec1: {
          name: 'Filtration MLLF',
          stamp: true
        },
        spec2: {
          name: 'Fresh air maximization',
          stamp: true
        },
        spec3: {
          name: 'Purification',
          stamp: false
        }
      },
      {
        title: 'Premium',
        code: 3,
        spec1: {
          name: 'Filtration MLLF+MIFD',
          stamp: true
        },
        spec2: {
          name: 'Fresh air maximization',
          stamp: true
        },
        spec3: {
          name: 'Purification',
          stamp: true
        }
      }
    ],
    valueCardsResults: [
      {
        title: 'green[air]',
        link: 'result-green-air',
        data: [
          {
            value: '29',
            unit: '%',
            text: `CO<sub>2</sub>  emissions reduction `,
            arrow: 'down',
            hvac: 'HVAC 2'
          },
          {
            value: '3.5',
            unit: 'months/ year',
            text: `Free of CO<sub>2</sub> emissions`,

          }
        ]
      },
      {
        title: 'clean[air]',
        link: 'result-clean-air',
        data: [
          {
            value: '64.73557075673364',
            value2: '54.63712056434373',
            unit: '%',
            text: 'HVAC 1',
            text2: 'HVAC 2',
            type: 'graph'
          }
        ]
      },
      {
        title: 'TCO',
        link: 'result-tco',
        data: [
          {
            value: '29',
            unit: '%',
            text: 'Savings',
            arrow: 'down',
            hvac: 'HVAC 2'
          },
          {
            value: '6.4',
            text: 'Breakeven',
            unit: 'years',
          }
        ]
      }
    ],
    combination: {},
    system1: null,
    system2: null,
    graphBarData: [
      {
        title: 'Initial cost',
        toCompare1title: 'HVAC 1',
        toCompare1val: '80',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      },
      {
        title: 'Energy consumption',
        toCompare1title: 'HVAC 1',
        toCompare1val: '75',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      },
      {
        title: 'Filters',
        toCompare1title: 'HVAC 1',
        toCompare1val: '55',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      },
      {
        title: 'Preventive',
        toCompare1title: 'HVAC 1',
        toCompare1val: '90',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      },
      {
        title: 'Corrective',
        toCompare1title: 'HVAC 1',
        toCompare1val: '80',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      },
      {
        title: 'Overhaul',
        toCompare1title: 'HVAC 1',
        toCompare1val: '80',
        toCompare2title: 'HVAC 2',
        toCompare2val: '100',
      }

    ],
    graphHorizontalBarData: {
      hvac1: 100,
      hvac2: 90,
      lines: [
        {
          hvac1: 10,
          hvac2: 10
        },
        {
          hvac1: 10,
          hvac2: 10
        },
        {
          hvac1: 10,
          hvac2: 30
        },
        {
          hvac1: 10,
          hvac2: 15
        },
        {
          hvac1: 10,
          hvac2: 15
        },
        {
          hvac1: 10,
          hvac2: 20
        },
      ],
    },
    graphSpiral: [
      {h1: 78, h2: 96},
      {h1: 33, h2: 66},
      {h1: 89, h2: 74},
    ],
    tcoGraph: {
      h1InitialCost: 1,
      h2InitialCost: 1,
      h1TotalCost: 1,
      h2TotalCost: 1,
      h1Year1: 1,
      h2Year1: 1,
      h1Year30: 1,
      h2Year30: 1,
      breakeven: 1,
    },
    cityDetails: {
      name: 'Shanghai',
      tempMax: 40,
      tempMin: -10,
      pm25: 31.5,
      nameC: String,
      tempMaxC: Number,
      tempMinC: Number,
      pm25C: Number
    }
  },
  getters: {
  },
  actions: {
    async reset_systems ({commit}) {
      try {
        commit('RESET_SYSTEMS')
      } catch (err) {
        console.log(err)
      }
    },
    async set_active_system ({commit}, value) {
      try {
        commit('SET_ACTIVE_SYSTEM', value)
      } catch (err) {
        console.log(err)
      }
    },
    async get_locations ({commit}) {
      try {
        const { data } = await $axios.get(api.city)
        commit('GET_LOCATIONS', data)
      } catch (err) {
        console.log(err)
      }
    },
    async set_systems_data ({commit}, {systemName, property, code, name}) {
      try {
        commit('SET_SYSTEMS_DATA', {
          systemName,
          name,
          code,
          property
        })
      } catch (err) {
        console.log(err)
      }
    },
    async set_feature ({commit}, {systemName, property1, property2, nameC, nameHP, codeC, codeHP}) {
      try {
        commit('SET_SYSTEMS_DATA', {
          systemName,
          name: nameC,
          code: codeC,
          property: property1
        })

        commit('SET_SYSTEMS_DATA', {
          systemName,
          name: nameHP,
          code: codeHP,
          property: property2
        })
      } catch (err) {
        console.log(err)
      }
    },
    async get_combination ({commit, state}) {
      try {
        let s1City = state.systems.system1.city.code
        let s2City = state.systems.system2.city.code
        let s1Refrigerant = state.systems.system1.refrigerant.code
        let s2Refrigerant = state.systems.system2.refrigerant.code
        let s1Control = state.systems.system1.control.code
        let s2Control = state.systems.system2.control.code
        let s1HeatPump = state.systems.system1.heatPump.code
        let s2HeatPump = state.systems.system2.heatPump.code
        let s1CleanAir = state.systems.system1.cleanAir.code
        let s2CleanAir = state.systems.system2.cleanAir.code

        let s1 = `${s1City}${s1Refrigerant}${s1Control}${s1HeatPump}${s1CleanAir}`
        let s2 = `${s2City}${s2Refrigerant}${s2Control}${s2HeatPump}${s2CleanAir}`

        const comb = await $axios.post(api.combination, {
          system1: s1,
          system2: s2,
        })

        const system1  = await $axios.post(api.getBySystem, {
          code: s1
        })
        const system2 = await $axios.post(api.getBySystem, {
          code: s2
        })

        commit('SET_COMBINATION', {
          value: comb.data.data,
          val1: system1.data.data,
          val2: system2.data.data
        })


        commit('SET_SYSTEM_INFO', {
          val1: system1.data.data,
          val2: system2.data.data
        })

        commit('RELOAD', false)
        if (comb.status === 200) {
          setTimeout(function () {
            commit('RELOAD', true)
          }, 1)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async get_combination_by_code ({commit}, {h1, h2}) {
      try {
        const comb = await $axios.post(api.combination, {
          // code: `${h1}-${h2}`
          system1: h1,
          system2: h2,
        })

        const system1  = await $axios.post(api.getBySystem, {
          code: `${h1}`
        })
        const system2 = await $axios.post(api.getBySystem, {
          code: `${h2}`
        })

        commit('SET_RESULTS_CARDS_PDF', {
          h1: h1,
          h2: h2
        })

        commit('SET_SYSTEM_INFO', {
          val1: system1.data.data,
          val2: system2.data.data
        })

        commit('SET_COMBINATION', {
          value: comb.data.data,
          val1: system1.data.data,
          val2: system2.data.data
        })

        commit('RELOAD', false)
        if (comb.status === 200) {
          setTimeout(function () {
            commit('RELOAD', true)
          }, 1)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async send_email ({commit, state}, email) {
      try {
        let s1City = state.systems.system1.city.code
        let s2City = state.systems.system2.city.code
        let s1Refrigerant = state.systems.system1.refrigerant.code
        let s2Refrigerant = state.systems.system2.refrigerant.code
        let s1Control = state.systems.system1.control.code
        let s2Control = state.systems.system2.control.code
        let s1HeatPump = state.systems.system1.heatPump.code
        let s2HeatPump = state.systems.system2.heatPump.code
        let s1CleanAir = state.systems.system1.cleanAir.code
        let s2CleanAir = state.systems.system2.cleanAir.code
        let s1 = `${s1City}${s1Refrigerant}${s1Control}${s1HeatPump}${s1CleanAir}`
        let s2 = `${s2City}${s2Refrigerant}${s2Control}${s2HeatPump}${s2CleanAir}`
        let webpage = `${process.env.VUE_APP_HOST}/custom-air/results-pdf?h1=${s1}&h2=${s2}`

        commit('LOADING', true)
        const send = await $axios.post(api.email, {
          url: webpage,
          email: email
        })
        if (send.status === 200) {
          commit('LOADING', false)
          commit('MESSAGE', 'Your PDF has been sent!')
        }
        if (send.status) {
          commit('LOADING', false)
        }
        if (send.status !== 200) {
          commit('ERR_MESSAGE', 'An error occurred, please try again!')
        }
        setTimeout(function () {
          commit('MESSAGE', undefined)
          commit('ERR_MESSAGE', undefined)
        }, 7000)
      } catch (err) {
        console.log(err)
      }
    }
  },
  mutations: {
    LOADING (state, value) {
      state.loading = value
    },
    MESSAGE (state, value) {
      state.message = value
    },
    ERR_MESSAGE (state, value) {
      state.errMessage = value
    },
    RESET_SYSTEMS (state) {
      state.systems = reset_systems()
    },
    GET_LOCATIONS (state, value) {
      state.locations = value
    },
    SET_ACTIVE_SYSTEM (state, value) {
      state.activeSystem = value
    },
    RELOAD (state, value) {
      state.reload = value
    },
    SET_SYSTEMS_DATA (state, {systemName, property, name, code}) {
      state.systems[systemName][property].name = name
      state.systems[systemName][property].code = code
    },
    SET_FEATURE (state, {systemName, feature}) {
      state.systems[systemName].features = feature
    },
    SET_COMBINATION (state, {value, val1, val2}) {
      state.combination = value

      // ******************************************************************************************************************
      // ADD DATA IN RESULT CARDS
      state.valueCardsResults[0].data[0].value = `${(value['Emissions reduction (%)'])}`
      state.valueCardsResults[0].data[0].hvac = `${(value['best greenair'])}`
      state.valueCardsResults[0].data[1].value = `${value['Months - free of emissions'] / 10}`
      state.valueCardsResults[2].data[0].value = `${value['TCO reduction (%)']}`
      state.valueCardsResults[2].data[0].hvac = `${(value['best TCO'])}`
      if (value['Breakeven'] === '>30') state.valueCardsResults[2].data[1].value = '>30'
      else if (typeof value['Breakeven'] === 'number') state.valueCardsResults[2].data[1].value = `${(value['Breakeven']) / 10}`
      else state.valueCardsResults[2].data[1].value = (0).toFixed(1)
  
      // END ADD DATA IN RESULT CARDS
      // ******************************************************************************************************************

      // ******************************************************************************************************************
      // ADD DATA FOR RADIAL GRAPH
      // Calc Green Air radial lines
      let getH1Code = state.systems.system1.cleanAir.code
      let getH2Code = state.systems.system2.cleanAir.code
      if (getH1Code === 1) state.graphSpiral[1].h1 = 33
      else if (getH1Code === 2) state.graphSpiral[1].h1 = 66
      else if (getH1Code === 3) state.graphSpiral[1].h1 = 100
      if (getH2Code === 1) state.graphSpiral[1].h2 = 33
      else if (getH2Code === 2) state.graphSpiral[1].h2 = 66
      else if (getH2Code === 3) state.graphSpiral[1].h2 = 100
      // Calc TCO radial lines
      state.graphSpiral[2].h1 = ((val1['total cost'] / value['Ref TCO HVAC 1']) / 10).toFixed()
      state.graphSpiral[2].h2 = ((val2['total cost'] / value['Ref TCO HVAC 1']) / 10).toFixed()
      // Calc Clean Air radial lines
      state.graphSpiral[0].h1 = ((value['Ref GA HVAC 1'] / val1['Carbon footprint (tonsCO2)']) * 100).toFixed()
      state.graphSpiral[0].h2 = ((value['Ref GA HVAC 1'] / val2['Carbon footprint (tonsCO2)']) * 100).toFixed()
      // END ADD DATA FOR RADIAL GRAPH
      // ******************************************************************************************************************

      // ******************************************************************************************************************
      // START TCO GRAPH
      state.tcoGraph.h1InitialCost = val1['Initial cost (€)']
      state.tcoGraph.h2InitialCost = val2['Initial cost (€)']
      state.tcoGraph.h1TotalCost = val1['total cost']
      state.tcoGraph.h2TotalCost = val2['total cost']
      state.tcoGraph.h1Year1 =  value['S1 TCO year 1']
      state.tcoGraph.h2Year1 =  value['S2 TCO year 1']
      state.tcoGraph.h1Year30 = value['S1 TCO year 2 to 30']
      state.tcoGraph.h2Year30 = value['S2 TCO year 2 to 30']
      state.tcoGraph.breakeven = value['Breakeven']
      if (value['Breakeven'] === '>30') state.tcoGraph.breakeven = '>30'
      else if (value['Breakeven'] === 'N/A') state.tcoGraph.breakeven = 0
      else state.tcoGraph.breakeven = value['Breakeven']
      // END TCO GRAPH
      // ******************************************************************************************************************


      // ******************************************************************************************************************
      // ADD City Details for CleanAir Result page
      // TODO:Change structure

      let city = value['Skyline']
      if (city === 'skyline-abuDhabi.svg') {
        state.cityDetails.name = state.locations.data.cities.others[7].location
        state.cityDetails.tempMax = state.locations.data.cities.others[7].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[7].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[7].pm25
      }
      if (city === 'skyline-baltimore.png') {
        state.cityDetails.name = state.locations.data.cities.merak[4].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[4].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[4].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[4].pm25
      }
      if (city === 'skyline-berlin.png') {
        state.cityDetails.name = state.locations.data.cities.others[1].location
        state.cityDetails.tempMax = state.locations.data.cities.others[1].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[1].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[1].pm25
      }
      if (city === 'skyline-helsinki.svg') {
        state.cityDetails.name = state.locations.data.cities.others[5].location
        state.cityDetails.tempMax = state.locations.data.cities.others[5].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[5].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[5].pm25
      }
      if (city === 'skyline-johannesburg.svg') {
        state.cityDetails.name = state.locations.data.cities.others[6].location
        state.cityDetails.tempMax = state.locations.data.cities.others[6].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[6].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[6].pm25
      }
      if (city === 'skyline-LA.png') {
        state.cityDetails.name = state.locations.data.cities.others[9].location
        state.cityDetails.tempMax = state.locations.data.cities.others[9].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[9].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[9].pm25
      }
      if (city === 'skyline-london.png') {
        state.cityDetails.name = state.locations.data.cities.others[3].location
        state.cityDetails.tempMax = state.locations.data.cities.others[3].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[3].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[3].pm25
      }
      if (city === 'skyline-madrid.png') {
        state.cityDetails.name = state.locations.data.cities.merak[1].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[1].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[1].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[1].pm25
      }
      if (city === 'skyline-milan.png') {
        state.cityDetails.name = state.locations.data.cities.others[4].location
        state.cityDetails.tempMax = state.locations.data.cities.others[4].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[4].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[4].pm25
      }
      if (city === 'skyline-NewDelhi.svg') {
        state.cityDetails.name = state.locations.data.cities.others[8].location
        state.cityDetails.tempMax = state.locations.data.cities.others[8].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[8].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[8].pm25
      }
      if (city === 'skyline-paris.png') {
        state.cityDetails.name = state.locations.data.cities.others[0].location
        state.cityDetails.tempMax = state.locations.data.cities.others[0].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[0].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[0].pm25
      }
      if (city === 'skyline-pune.svg') {
        state.cityDetails.name = state.locations.data.cities.merak[5].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[5].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[5].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[5].pm25
      }
      if (city === 'skyline-sao paulo.svg') {
        state.cityDetails.name = state.locations.data.cities.others[2].location
        state.cityDetails.tempMax = state.locations.data.cities.others[2].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.others[2].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.others[2].pm25
      }
      if (city === 'skyline-shanghai.png') {
        state.cityDetails.name = state.locations.data.cities.merak[0].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[0].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[0].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[0].pm25
      }
      if (city === 'skyline-sydney.svg') {
        state.cityDetails.name = state.locations.data.cities.merak[3].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[3].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[3].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[3].pm25
      }
      if (city === 'skyline-vienna.png') {
        state.cityDetails.name = state.locations.data.cities.merak[2].location
        state.cityDetails.tempMax = state.locations.data.cities.merak[2].temperature.max
        state.cityDetails.tempMin = state.locations.data.cities.merak[2].temperature.min
        state.cityDetails.pm25 = state.locations.data.cities.merak[2].pm25
      }
      state.locations.data.cities.merak.forEach(e => {
        if (val1.City === e.code) {
          state.cityDetails.nameC = e.location
          state.cityDetails.tempMaxC = e.temperature.max
          state.cityDetails.tempMinC = e.temperature.min
          state.cityDetails.pm25C = e.pm25
        }
      })
      state.locations.data.cities.others.forEach(e => {
        if (val1.City === e.code) {
          state.cityDetails.nameC = e.location
          state.cityDetails.tempMaxC = e.temperature.max
          state.cityDetails.tempMinC = e.temperature.min
          state.cityDetails.pm25C = e.pm25
        }
      })
      // END City Details for CleanAir Result page
      // ******************************************************************************************************************
    },
    SET_SYSTEM_INFO (state, {val1, val2}) {
      state.system1 = val1
      state.system2 = val2


      let aqi = val1.AQI
      let aqi2 = val2.AQI

        state.valueCardsResults[1].data[0].value = `${aqi.toFixed(1)}`
        state.valueCardsResults[1].data[0].value2 = `${aqi2.toFixed(1)}`
      // Add Initial cost (€) in %
      if (val1['Initial cost (€)'] > val2['Initial cost (€)']) {
        let y = val2['Initial cost (€)'] * 100 / val1['Initial cost (€)']
        state.graphBarData[0].toCompare1val = 100
        state.graphBarData[0].toCompare2val = y.toFixed()
      } else if (val1['Initial cost (€)'] < val2['Initial cost (€)']) {
        let y = val1['Initial cost (€)'] * 100 / val2['Initial cost (€)']
        state.graphBarData[0].toCompare1val = y.toFixed()
        state.graphBarData[0].toCompare2val = 100
      }else if (val1['Initial cost (€)'] === val2['Initial cost (€)']) {
        state.graphBarData[0].toCompare1val = 100
        state.graphBarData[0].toCompare2val = 100
      }
      // Add E. consumption (kWh) cost in %
      if (val1['Energy'] > val2['Energy']) {
        let y = val2['Energy'] * 100 / val1['Energy']
        state.graphBarData[1].toCompare1val = 100
        state.graphBarData[1].toCompare2val = y.toFixed()
      } else if (val1['Energy'] < val2['Energy']) {
        let y = val1['Energy'] * 100 / val2['Energy']
        state.graphBarData[1].toCompare1val = y.toFixed()
        state.graphBarData[1].toCompare2val = 100
      }else if (val1['Energy'] === val2['Energy']) {
        state.graphBarData[1].toCompare1val = 100
        state.graphBarData[1].toCompare2val = 100
      }
      // Add Filters in %
      if (val1['Filters'] > val2['Filters']) {
        let y = val2['Filters'] * 100 / val1['Filters']
        state.graphBarData[2].toCompare1val = 100
        state.graphBarData[2].toCompare2val = y.toFixed()
      } else if (val1['Filters'] < val2['Filters']) {
        let y = val1['Filters'] * 100 / val2['Filters']
        state.graphBarData[2].toCompare1val = y.toFixed()
        state.graphBarData[2].toCompare2val = 100
      }else if (val1['Filters'] === val2['Filters']) {
        state.graphBarData[2].toCompare1val = 100
        state.graphBarData[2].toCompare2val = 100
      }
      // Add Preventive in %
      if (val1['Preventive'] > val2['Preventive']) {
        let y = val2['Preventive'] * 100 / val1['Preventive']
        state.graphBarData[3].toCompare1val = 100
        state.graphBarData[3].toCompare2val = y.toFixed()
      } else if (val1['Preventive'] < val2['Preventive']) {
        let y = val1['Preventive'] * 100 / val2['Preventive']
        state.graphBarData[3].toCompare1val = y.toFixed()
        state.graphBarData[3].toCompare2val = 100
      }else if (val1['Preventive'] === val2['Preventive']) {
        state.graphBarData[3].toCompare1val = 100
        state.graphBarData[3].toCompare2val = 100
      }
      // Add Corrective in %
      if (val1['Corrective'] > val2['Corrective']) {
        let y = val2['Corrective'] * 100 / val1['Corrective']
        state.graphBarData[4].toCompare1val = 100
        state.graphBarData[4].toCompare2val = y.toFixed()
      } else if (val1['Corrective'] < val2['Corrective']) {
        let y = val1['Corrective'] * 100 / val2['Corrective']
        state.graphBarData[4].toCompare1val = y.toFixed()
        state.graphBarData[4].toCompare2val = 100
      }else if (val1['Corrective'] === val2['Corrective']) {
        state.graphBarData[4].toCompare1val = 100
        state.graphBarData[4].toCompare2val = 100
      }
      // Add Overhaul in %
      if (val1['Overhaul'] > val2['Overhaul']) {
        let y = val2['Overhaul'] * 100 / val1['Overhaul']
        state.graphBarData[5].toCompare1val = 100
        state.graphBarData[5].toCompare2val = y.toFixed()
      } else if (val1['Overhaul'] < val2['Overhaul']) {
        let y = val1['Overhaul'] * 100 / val2['Overhaul']
        state.graphBarData[5].toCompare1val = y.toFixed()
        state.graphBarData[5].toCompare2val = 100
      }else if (val1['Overhaul'] === val2['Overhaul']) {
        state.graphBarData[5].toCompare1val = 100
        state.graphBarData[5].toCompare2val = 100
      }

      let sumHvac1 = val1['Initial cost (€)'] +
                     val1['Energy'] +
                     val1['Filters'] +
                     val1['Preventive'] +
                     val1['Corrective'] +
                     val1['Overhaul']
      let sumHvac2 = val2['Initial cost (€)'] +
                     val2['Energy'] +
                     val2['Filters'] +
                     val2['Preventive'] +
                     val2['Corrective'] +
                     val2['Overhaul']

      let totalLength = 0
      if (sumHvac1 > sumHvac2) {
        totalLength = sumHvac1
        state.graphHorizontalBarData.hvac1 = 100
        state.graphHorizontalBarData.hvac2 = sumHvac2 * 100 / sumHvac1
        state.graphHorizontalBarData.lines[0].hvac1 = val1['Initial cost (€)'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[1].hvac1 = val1['Energy'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[2].hvac1 = val1['Filters'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[3].hvac1 = val1['Preventive'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[4].hvac1 = val1['Corrective'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[5].hvac1 = val1['Overhaul'] * 100 / sumHvac1

        state.graphHorizontalBarData.lines[0].hvac2 = val2['Initial cost (€)'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[1].hvac2 = val2['Energy'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[2].hvac2 = val2['Filters'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[3].hvac2 = val2['Preventive'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[4].hvac2 = val2['Corrective'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[5].hvac2 = val2['Overhaul'] * 100 / sumHvac2

      } else if (sumHvac1 < sumHvac2) {
        totalLength = sumHvac2
        state.graphHorizontalBarData.hvac2 = 100
        state.graphHorizontalBarData.hvac1 = sumHvac1 * 100 / sumHvac2

        state.graphHorizontalBarData.lines[0].hvac1 = val1['Initial cost (€)'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[1].hvac1 = val1['Energy'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[2].hvac1 = val1['Filters'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[3].hvac1 = val1['Preventive'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[4].hvac1 = val1['Corrective'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[5].hvac1 = val1['Overhaul'] * 100 / sumHvac1

        state.graphHorizontalBarData.lines[0].hvac2 = val2['Initial cost (€)'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[1].hvac2 = val2['Energy'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[2].hvac2 = val2['Filters'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[3].hvac2 = val2['Preventive'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[4].hvac2 = val2['Corrective'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[5].hvac2 = val2['Overhaul'] * 100 / sumHvac2

      } else if (sumHvac1 === sumHvac2) {
        state.graphHorizontalBarData.hvac1 = 100
        state.graphHorizontalBarData.hvac2 = 100
        state.graphHorizontalBarData.lines[0].hvac1 = val1['Initial cost (€)'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[1].hvac1 = val1['Energy'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[2].hvac1 = val1['Filters'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[3].hvac1 = val1['Preventive'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[4].hvac1 = val1['Corrective'] * 100 / sumHvac1
        state.graphHorizontalBarData.lines[5].hvac1 = val1['Overhaul'] * 100 / sumHvac1

        state.graphHorizontalBarData.lines[0].hvac2 = val2['Initial cost (€)'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[1].hvac2 = val2['Energy'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[2].hvac2 = val2['Filters'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[3].hvac2 = val2['Preventive'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[4].hvac2 = val2['Corrective'] * 100 / sumHvac2
        state.graphHorizontalBarData.lines[5].hvac2 = val2['Overhaul'] * 100 / sumHvac2
      }
    },

    SET_RESULTS_CARDS_PDF(state, {h1, h2}) {
      const cityCodeH1 = h1.charAt(0)
      const cityCodeH2 = h2.charAt(0)
      const refCodeH1 = h1.charAt(1)
      const refCodeH2 = h2.charAt(1)
      const featuresCodeH1 = h1.charAt(2)
      const featuresCodeH2 = h2.charAt(2)
      const pumpCodeH1 = h1.charAt(3)
      const pumpCodeH2 = h2.charAt(3)
      const cleanCodeH1 = h1.charAt(4)
      const cleanCodeH2 = h2.charAt(4)

      state.locations.data.cities.merak.forEach(e => {
        if (cityCodeH1 === e.code) {
          state.systems.system1.city.name = e.location
          state.systems.system1.city.code = cityCodeH1
        }
      })
      state.locations.data.cities.merak.forEach(e => {
        if (cityCodeH2 === e.code) {
          state.systems.system2.city.name = e.location
          state.systems.system2.city.code = cityCodeH2
        }
      })
      state.locations.data.cities.others.forEach(e => {
        if (cityCodeH1 === e.code) {
          state.systems.system1.city.name = e.location
          state.systems.system1.city.code = cityCodeH1
        }
      })
      state.locations.data.cities.others.forEach(e => {
        if (cityCodeH2 === e.code) {
          state.systems.system2.city.name = e.location
          state.systems.system2.city.code = cityCodeH2
        }
      })
      switch (refCodeH1) {
        case '1':
          state.systems.system1.refrigerant.name = 'R513A'
          state.systems.system1.refrigerant.code = 1
          break
        case '2':
          state.systems.system1.refrigerant.name = 'R454C'
          state.systems.system1.refrigerant.code = 2
          break
        case '3':
          state.systems.system1.refrigerant.name = 'R290'
          state.systems.system1.refrigerant.code = 3
          break
        case '4':
          state.systems.system1.refrigerant.name = 'R1234yf'
          state.systems.system1.refrigerant.code = 4
          break
        case '5':
          state.systems.system1.refrigerant.name = 'R744'
          state.systems.system1.refrigerant.code = 5
          break
      }
      switch (refCodeH2) {
        case '1':
          state.systems.system2.refrigerant.name = 'R513A'
          state.systems.system2.refrigerant.code = 1
          break
        case '2':
          state.systems.system2.refrigerant.name = 'R454C'
          state.systems.system2.refrigerant.code = 2
          break
        case '3':
          state.systems.system2.refrigerant.name = 'R290'
          state.systems.system2.refrigerant.code = 3
          break
        case '4':
          state.systems.system2.refrigerant.name = 'R1234yf'
          state.systems.system2.refrigerant.code = 4
          break
        case '5':
          state.systems.system2.refrigerant.name = 'R744'
          state.systems.system2.refrigerant.code = 5
          break
      }
      switch (featuresCodeH1) {
        case '1':
          state.systems.system1.control.name = 'Tandem'
          state.systems.system1.control.code = 2
          break
        case '2':
          state.systems.system1.control.name = 'HGBP'
          state.systems.system1.control.code = 1
          break
        case '3':
          state.systems.system1.control.name = 'Inverter'
          state.systems.system1.control.code = 3
          break
      }
      switch (featuresCodeH2) {
        case '1':
          state.systems.system2.control.name = 'Tandem'
          state.systems.system2.control.code = 2
          break
        case '2':
          state.systems.system2.control.name = 'HGBP'
          state.systems.system2.control.code = 1
          break
        case '3':
          state.systems.system2.control.name = 'Inverter'
          state.systems.system2.control.code = 3
          break
      }
      switch (pumpCodeH1) {
        case '1':
          state.systems.system1.heatPump.name = 'HP-on'
          state.systems.system1.heatPump.code = 1
          break
        case '2':
          state.systems.system1.heatPump.name = 'HP-off'
          state.systems.system1.heatPump.code = 2
          break
      }
      switch (pumpCodeH2) {
        case '1':
          state.systems.system2.heatPump.name = 'HP-on'
          state.systems.system2.heatPump.code = 1
          break
        case '2':
          state.systems.system2.heatPump.name = 'HP-off'
          state.systems.system2.heatPump.code = 2
          break
      }
      switch (cleanCodeH1) {
        case '1':
          state.systems.system1.cleanAir.name = 'Conventional'
          state.systems.system1.cleanAir.code = 1
          break
        case '2':
          state.systems.system1.cleanAir.name = 'Advanced'
          state.systems.system1.cleanAir.code = 2
          break
        case '3':
          state.systems.system1.cleanAir.name = 'Premium'
          state.systems.system1.cleanAir.code = 3
          break
      }
      switch (cleanCodeH2) {
        case '1':
          state.systems.system2.cleanAir.name = 'Conventional'
          state.systems.system2.cleanAir.code = 1
          break
        case '2':
          state.systems.system2.cleanAir.name = 'Advanced'
          state.systems.system2.cleanAir.code = 2
          break
        case '3':
          state.systems.system2.cleanAir.name = 'Premium'
          state.systems.system2.cleanAir.code = 3
          break
      }
    }
  },
  modules: {
  }
})
